import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Header = () => {
  return (
    <div className="bg-gray-white font-inter pt-44 mb-48">
      <div className="grid grid-cols-12 w-full h-full">
        <div className="col-span-0 lg:col-span-1 2xl:col-span-2"></div>
        <div className="col-span-12 lg:col-span-5 xl:col-span-5 2xl:col-span-3 px-12 flex flex-col  md:px-16">
          <h2 className="text-3xl sm:text-5xl font-semibold ">
            Leer ons kennen
          </h2>
          
        </div>
        <div className="col-span-0 lg:col-span-1 lg:col-span-0 2xl:col-span-1"></div>
        <div className="col-span-0 lg:hidden 2xl:block xl:col-span-1"></div>
        <div className="col-span-12  lg:col-span-5 2xl:col-span-4 xl:col-span-5  bg-gray-white mt-16 lg:mt-0 px-12 md:px-16 lf:px-0  text-2xl md:text-3xl lg:text-3xl xl:text-5xl text-left font-light md:font-normal">
          <div>
            <div>
              <AnchorLink to="/team#Kristof" title="Kristof">
                Kristof Callebaut
              </AnchorLink>
            </div>
            <div>
              <AnchorLink to="/team#Annette" title="Annette">
                Annette Van Thienen
              </AnchorLink>
            </div>
            <div>
              <AnchorLink to="/team#Veerle" title="Veerle">
                Veerle Roggeman
              </AnchorLink>
            </div>
            <div>
              <AnchorLink to="/team#Ine" title="Ine">
                Ine Pocket
              </AnchorLink>
            </div>
            <div>
              <AnchorLink to="/team#Tess" title="Tess">
                Tess Speltens
              </AnchorLink>
            </div>
            <div>
              <AnchorLink to="/team#Laura" title="Laura">
                Laura Van Iersel
              </AnchorLink>
            </div>
            <div>
              <AnchorLink to="/team#Tom" title="Tom">
                Tom Hofman
              </AnchorLink>
            </div>
            <div>
              <AnchorLink to="/team#Yana" title="Yana">
                Yana Breynaert
              </AnchorLink>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Header;
